<template>
  <div class="multi-channel-detail-card row">
    <div class="detail-card-content col-md-9">
      <div class="detail-card-header" :class="'detail-color-' + getFirstLetterInUpperCase(messageType)">
        <span class="badge">{{ getFirstLetterInUpperCase(messageType) }}</span>
        <span class="txt-badge">{{ messageType }}</span>
      </div>
      <div class="detail-card-body" :class="final ? 'detail-success-body' : ''">
        <span class="mb-1">{{ (fail) ? "실패" : "성공" }}</span>
        <el-popover placement="bottom">
          <span>{{ codeDesc }}</span>
          <base-button slot="reference" size="sm" type="default" outline>{{ brokerStatusCode }}</base-button>
        </el-popover>
      </div>  
    </div>
    <div class="detail-card-arrow col-md-2" v-if="!final">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
  export default {
    name: "MultiChannelDeatilCard",
    components: {},
    props: {
      messageType       : String  ,
      brokerStatusCode  : String  ,
      fail              : Boolean ,
      final             : Boolean ,
      codeDesc          : String
    },
    methods: {
      getFirstLetterInUpperCase(type) {
        if (!type) return ""
        return type.substring(0, 1);
      }
    },
    watch: {
    }
  };
</script>
<style lang="scss">
.detail-card-header {
  margin-bottom: 12px;
}

.detail-card-header .badge {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 4px 6px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  vertical-align: top;
  box-sizing: border-box;
  text-align: center;
}

.detail-card-header .txt-badge {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.detail-color-A, .detail-color-F {
  color: #ffd600;
  .badge {
    background: rgba(255, 214, 0, 0.2);
  }
}

.detail-color-S, .detail-color-L, .detail-color-M {
  color: #5e72e4;
  .badge {
    background: rgba(159, 169, 226, 0.2);
  }
}

.detail-color-R {
  color: #f5364c;
  .badge {
    background: rgba(245, 54, 76, 0.2);
  }
}

.detail-color-N {
  color: #2dce89;
  .badge {
    background: rgba(45, 206, 137, 0.2);
  }
}

.detail-card-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.detail-card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 118px;
  height: 100px;
  background: #fff;
  border: 1px solid #E5E7EC;
  border-radius: 6px;
  box-sizing: border-box;
  text-decoration: none;
}

.detail-success-body {
  background: rgb(245, 245, 245);
  border: 1px solid #abadb2;
}
</style>

<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer" size="xlg">
    <h6 slot="header" class="modal-title">발송이력 상세</h6>
    <template>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label form-control-label">고객ID</label>
        <div class="col-md-4">
          <base-input v-model="clientId" disabled>
          </base-input>
        </div>
        <label class="col-md-2 col-form-label form-control-label">메시지ID</label>
        <div class="col-md-4">
          <base-input v-model="info.uid" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label form-control-label">발송일</label>
        <div class="col-md-4">
          <base-input v-model="info.reqDate" disabled>
          </base-input>
        </div>
        <label class="col-md-2 col-form-label form-control-label">결과 수신일</label>
        <div class="col-md-4">
          <base-input v-model="info.resDate" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label form-control-label">발신번호</label>
        <div class="col-md-4">
          <base-input v-model="info.senderNo" disabled>
          </base-input>
        </div>
        <label class="col-md-2 col-form-label form-control-label">수신번호</label>
        <div class="col-md-4">
          <base-input v-model="info.phoneNumber" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label form-control-label">템플릿 코드</label>
        <div class="col-md-4">
          <base-input v-model="info.templateCode" disabled>
          </base-input>
        </div>
        <label class="col-md-2 col-form-label form-control-label">최종 상태코드</label>
        <div class="col-md-4">
          <base-input v-model="info.clientStatusCode" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-10">
        <label class="col-md-2 col-form-label form-control-label">내용</label>
        <div class="col-md-10">
          <textarea class="form-control" rows="5" disabled v-model="info.message"></textarea>
        </div>
      </div>
      <div class="form-group row mb-10">
        <label class="col-md-2 col-form-label form-control-label">대체 발송 내용</label>
        <div class="col-md-10">
          <textarea class="form-control" rows="5" disabled v-model="info.fallbackMessage"></textarea>
        </div>
      </div>
    </template>
    <h4 class="mb-3">발송결과</h4>
    <template>
      <div class="row">
        <multi-channel-detail-card
          v-for="detail, index in multiChannelDetailList"
          class="col-md-3"
          :key="detail.uid + '-' + index"
          :message-type="detail.messageType"
          :broker-status-code="detail.brokerStatusCode"
          :code-desc="detail.codeDesc"
          :final="detail.final"
          :fail="detail.fail"
        >
        </multi-channel-detail-card>
      </div>
    </template>
    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX } from '@/shared/util/const';
  import MultiChannelDetailCard from './MultiChannelDetailCard.vue'

  export default {
    name: 'MultiChannelResultLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      MultiChannelDetailCard
    },
    props: {
      clientId    : String,
      uid         : String
    },
    computed: {

    },
    data() {
      return {
        info: {
          uid                 : '' ,
          reqDate             : '' ,
          resDate             : '' ,
          clientStatusCode    : '' ,
          phoneNumber         : '' ,
          senderNo            : '' ,
          message             : '' ,
          fallbackMessage     : '' ,
          templateCode        : ''
        },
        multiChannelDetailList: []
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      /**
       * 상태코드에 따른 한글 결과 변환
       */
      convertStatusCode(statusCode, clientStatusCode) {
          switch (statusCode) {
            case 'NRM00000':
              return '성공';
            case 'NRM10000':
              return '발송중';
            default:
              return clientStatusCode;
        }
      },

      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },

      /**
       * 메시지정보 정보 조회
       */
      getInfo() {
        this.$axios.get(`${USER_API_PREFIX}/multi-channel/detail`, {
          params: this.setParams()
        }).then(res => {
          this.multiChannelDetailList = res.data.result.info;
          
          const stepSize = this.multiChannelDetailList.length - 1;     
          this.info.uid                 = this.multiChannelDetailList[0].uid                       ;
          this.info.reqDate             = this.multiChannelDetailList[0].reqDate                   ;
          this.info.resDate             = this.multiChannelDetailList[stepSize].resDate            ;
          this.info.clientStatusCode    = this.convertStatusCode(this.multiChannelDetailList[stepSize].statusCode, this.multiChannelDetailList[stepSize].clientStatusCode); 
          this.info.phoneNumber         = this.multiChannelDetailList[0].phoneNumber               ;
          this.info.senderNo            = this.multiChannelDetailList[0].senderNo                  ;
          this.info.message             = this.multiChannelDetailList[0].message                   ;
          this.info.fallbackMessage     = this.multiChannelDetailList[0].fallbackMessage           ;
          this.info.templateCode        = this.multiChannelDetailList[0].templateCode              ;
        });
      },

      setParams() {
        return {
          clientId    : this.clientId     ,
          uid         : this.uid          
        }
      }
    }
  };
</script>
<style>
</style>

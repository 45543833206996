<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">다채널 발송 조회</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">다채널 발송 목록</h3>
          </template>
          <!--상세보기 레이어-->
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap" style="margin-bottom: -10px;">
              <div class="form-inline">
                <div class="mb-0 col-md-12 form-group pl-0 pr-0">
                  <div class="mb-2 col-md-12 pl-0 pr-0 form-inline">
                    <label class="col-md-1 col-form-label form-control-label"><span class="text-danger">*</span>발송일시</label>
                    <div class="col-1 pl-0">
                      <el-select v-model="search.startYearValue"
                                 filterable
                                 placeholder="연도 선택"
                                 @change="resetDate('YEAR')">
                        <el-option v-for="option in yearList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-1 pl-0">
                      <el-select v-model="search.startMonthValue"
                                 filterable
                                 placeholder="월 선택"
                                 @change="resetDate('MONTH')">
                        <el-option v-for="option in monthList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-1 pl-0 pr-0 mr-3">
                      <el-select v-model="search.startDayValue"
                                 filterable
                                 placeholder="일 선택"
                                 @change="resetDate('DAY')">
                        <el-option v-for="option in dayList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="col-md-12 pl-0 pr-0 form-inline">
                    <div class="mb-2 col-md-3 form-group pl-0 pr-0">
                      <label class="col-md-4 col-form-label form-control-label"><span class="text-danger">*</span>메시지유형</label>
                      <div class="col-md-8 pl-0 pr-0 form-inline">
                        <div class="pl-0">
                          <el-select v-model="search.messageType"
                                    filterable
                                    placeholder="메시지유형 선택">
                            <el-option v-for="option in msgCodeList"
                                :key="option.msgTp"
                                :label="`${option.msgTp} (${option.msgTpNm})`"
                                :value="option.msgTp">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 col-md-3 form-group pl-0 pr-0">
                      <label class="col-md-4 col-form-label form-control-label"><span class="text-danger">*</span>전화번호</label>
                      <base-input placeholder="전화번호 입력"
                                  @keyup="onlyNumber"
                                  :maxlength="15"
                                  v-model="search.phoneNumber">
                      </base-input>
                    </div>
                    <div class="mb-2 col-md-3 form-group pl-0 pr-0" v-if="session.clientType == 'R'">
                      <label class="col-md-4 col-form-label form-control-label">고객사</label>
                      <div class="col-md-8 pl-0 pr-0 form-inline">
                        <div class="pl-0">
                          <el-select v-model="search.clientId"
                                    filterable
                                    placeholder="고객사 선택">
                            <el-option v-for="option, index in clientNameList"
                                      :key="option.label + '-' + index"
                                      :label="option.label"
                                      :value="option.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 pl-0 pr-0 form-inline">
                    <div class="mb-2 col-md-3 form-group pl-0 pr-0">
                      <label class="col-md-4 col-form-label form-control-label">성공여부</label>
                      <div class="col-md-8 pl-0 pr-0 form-inline">
                        <div class="pl-0">
                          <el-select v-model="search.successYn"
                                     filterable
                                     placeholder="성공여부">
                            <el-option v-for="option in selectSuccessOptions"
                                       :key="option.value"
                                       :label="option.label"
                                       :value="option.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 col-md-3 form-group pl-0 pr-0">
                      <label class="col-md-4 col-form-label form-control-label">고객 KEY</label>
                      <base-input placeholder="고객 KEY 입력"
                                  :maxlength="50"
                                  v-model="search.cid">
                      </base-input>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

            <div v-if="session.roleCode === 'M' && multiChannelResultList.length" class="float-right" style="margin: 20px 40px 10px 0;">
              <base-button type="primary" @click="excelDownload">엑셀 다운로드</base-button>
            </div>
            <div class="float-right" style="margin: 20px 30px 10px 0;">
              <base-button class="btn base-button btn-block btn-primary" outline type="primary" @click="retrieveMultiChannelResultList(1)" style="min-width: 120px;">검색</base-button>
            </div>
            <el-table :data="multiChannelResultList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'조회된 정보가 없습니다.'"
                      class="table-responsive table-flush"
                      style="height: 50%;overflow-y: auto; padding: 5px 5px 5px 5px;">
              <el-table-column label="번호" min-width="6%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="cid" label="고객KEY" min-width="9%">
                <template slot-scope="scope">
                  {{ scope.row.cid }}
                </template>
              </el-table-column>
              <el-table-column prop="regDate" label="발송일시" min-width="11%">
                <template slot-scope="scope">
                  {{ scope.row.reqDate }}
                </template>
              </el-table-column>
              <el-table-column prop="endDate" label="결과수신일" min-width="11%">
                <template slot-scope="scope">
                  {{ scope.row.resDate }}
                </template>
              </el-table-column>
              <el-table-column prop="phoneNumber" label="수신번호" min-width="10%">
                <template slot-scope="scope">
                  {{ scope.row.phoneNumber }}
                </template>
              </el-table-column>
              <el-table-column prop="senderNo" label="발신번호" min-width="10%">
                <template slot-scope="scope">
                  {{ scope.row.senderNo }}
                </template>
              </el-table-column>
              <el-table-column prop="messageType" label="요청 타입" min-width="8%">
                <template slot-scope="scope">
                  {{ scope.row.messageType }}
                </template>
              </el-table-column>
              <el-table-column prop="messageType" label="최종 타입" min-width="8%">
                <template slot-scope="scope">
                  {{ scope.row.finalMessageType }}
                </template>
              </el-table-column>
              <el-table-column prop="offerCode" label="발송유형" min-width="8%">
                <template slot-scope="scope">
                  {{ scope.row.offerCode }}
                </template>
              </el-table-column>
              <el-table-column prop="statusCode" label="최종 상태코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom">
                    <span>{{ scope.row.codeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{convertStatusCode(scope.row.statusCode, scope.row.clientStatusCode)}}</base-button>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="seqNo" label="상세보기" min-width="10%">
                <template slot-scope="scope">
                  <base-button type="info" size="sm" @click="openDetailLayer(scope.row.clientId, scope.row.uid)">
                    상세보기
                  </base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrieveMultiChannelResultList">
            </base-pagination>

            <multi-channel-result-layer
              v-if="showDetailLayer"
              :client-id="targetClientId"
              :uid="targetUid"
              @close="closeLayer">
            </multi-channel-result-layer>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX , RESULT_CODE} from '@/shared/util/const'
  import MultiChannelResultLayer from './MultiChannelResultLayer.vue';
  import swal from "sweetalert2";
  import {mapGetters} from "vuex";

  export default {
    name: 'MultiChannelResult',
    mixins: [ commFlatpickrMixin, commJsUtilMixin, commPaginationMixin ],
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    filters: {
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      MultiChannelResultLayer
    },
    created() {
      // 연도 목록 세팅
      let fullDays                = this._fullDays('Y', 0);
      this.yearList               = fullDays.yearList;
      this.search.startYearValue  = fullDays.year;

      // 월 목록 세팅
      this.monthList              = fullDays.monthList;
      this.search.startMonthValue = fullDays.month;

      // 일 목록 세팅
      this.dayList                = fullDays.dayList;
      this.search.startDayValue   = fullDays.day;

      if(this.session.clientType == 'R'){
        this.retrieveSubClientNameList();
      }

      this.setParams(1)

      // 타입목록 조회
      this.retriveTypeList()
    },
    data() {
      return {
        showDetailLayer   : false,
        targetUid         : '',
        targetClientId    : '',
        multiChannelResultList: [],
        clientNameList    : [],
        yearList          : [],
        monthList         : [],
        dayList           : [],
        selectDateOptions : [
          {
            label: '연별',
            value: 'YEAR'
          },
          {
            label: '월별',
            value: 'MONTH'
          },
          {
            label: '일별',
            value: 'DAY'
          }
        ],
        selectSuccessOptions : [
          {
            label: '선택',
            value: ''
          },
          {
            label: '성공',
            value: 'Y'
          },
          {
            label: '실패',
            value: 'N'
          }
        ],
        search: {
          dateTypeCondition : 'DAY' ,
          startYearValue    : null  ,
          startMonthValue   : null  ,
          startDayValue     : this.$moment(new Date()).format('YYYY-MM-DD') ,
          clientId          : ''    ,
          messageType       : ''    ,
          successYn         : ''    ,
          phoneNumber       : ''    ,
          cid               : ''
        },
        msgCodeList : []
      }
    },
    methods: {

      /**
       * 상태코드에 따른 한글 결과 변환
       */
      convertStatusCode(statusCode, clientStatusCode) {
          switch (statusCode) {
            case 'NRM00000':
              return '성공';
            case 'NRM10000':
              return '발송중';
            default:
              return clientStatusCode;
        }
      },

      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },

      /**
       *
       */
       retrieveSubClientNameList() {
        this.$axios.get(`${USER_API_PREFIX}/resell/sub-clients`, {
        }).then((res) => {
          this.clientNameList = res.data.result.clientNameList;
          this.clientNameList.unshift({
            value: '',
            label: '== 전체 =='
          })
        }).catch((error) => {
          console.log(error)
        })
      },

      /**
       * 메시지 타입 목록 조회
       */
       retriveTypeList(){
        this.$axios.get(`${USER_API_PREFIX}/code/type/list`, {
        }).then((res) => {
          this.msgCodeList = res.data.result.msgCodeList;
        }).catch((error) => {
          console.log(error)
        })
      },

      /**
       * 다채널발송 목록 조회
       */
      retrieveMultiChannelResultList(page) {
        if(this.validateCheck()) {
          let pageNo = page ? page : this.pagination.currentPage;
          this.$axios.get(`${USER_API_PREFIX}/multi-channel/result`, {
            params: this.setParams(pageNo)
          }).then(res => {
            this.multiChannelResultList = res.data.result.multiChannelResultList; // 목록정보 세팅
            this.pagination.currentPage = pageNo; // 페이지 번호 세팅
            this.pagination.total = res.data.result.total; // 전체페이지수 세팅
          });
        }
      },

      /**
       * 유효성검사
       */
      validateCheck() {
        // 발송타입 유효성 검사
        if(this.checkNull(this.search.messageType)) {
          this.alertMsg('메시지유형을 선택해 주세요.');
          return false;
        }

        // 조회일 유효성 검사
        if(this.checkNull(this.search.startMonthValue) || this.checkNull(this.search.startDayValue)) {
          this.alertMsg('발송일시를 선택해 주세요.');
          return false;
        }

        if(this.checkNull(this.search.phoneNumber)){
          this.alertMsg('전화번호를 입력해 주세요.');
          return false;
        }

        return true;
      },

      /**
       * 연도/월/일자에 선택에 따른 날짜 초기화
       */
      resetDate(period) {
        switch (period) {
          case 'YEAR' :
            this.search.startMonthValue = ''
            this.search.startDayValue   = ''
            break
          case 'MONTH' :
            this.search.startDayValue   = ''
            break
        }
      },

      /**
       * 상세 레이어 생성
       */
      openDetailLayer(clientId, uid) {
        this.showDetailLayer = true;

        this.targetClientId = clientId;
        this.targetUid = uid;
      },

      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.showDetailLayer = false;

        this.targetClientId = '';
        this.targetUid = '';
      },

      /**
       * 키 이벤트로 오직 숫자만 입력되도록 처리
       */
      onlyNumber() {
        this.search.phoneNumber = this._onlyNumber(this.search.phoneNumber);
      },

      /**
       * null check
       */
      checkNull(param){
        if(param === undefined || param === null || '' == param){
          return true;
        }else{
          return false;
        }
      },

      /**
       * 일별/월별에 따른 파라미터 세팅
       */
      setParams(pageNo) {
        return {
          page             : pageNo ? pageNo : 1             ,
          rows             : Number(this.pagination.perPage) ,
          clientId         : this.search.clientId            ,
          startYear        : this.search.startYearValue      ,
          startMonth       : this.search.startMonthValue     ,
          startDay         : this.search.startDayValue       ,
          endYear          : this.search.startYearValue      ,
          endMonth         : this.search.startMonthValue     ,
          endDay           : this.search.startDayValue       ,
          phoneNumber      : this.search.phoneNumber         ,
          successYn        : this.search.successYn           ,
          messageType      : this.search.messageType         ,
          cid              : this.search.cid                 
        }
      }
    }
  }
</script>